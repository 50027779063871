import React from "react"
import Layout from "../../components/Layout/layout"
import { Container } from "@material-ui/core"
import PageInfoSection from "../../components/PageInfoSection/PageInfoSection"
import ImageCard from '../../components/PageInfoSection/components/ImageCard'
import image from '../../assets/images/web-hosting.jpeg'
import Squares from '../../components/Squares/Squares'
import { graphql, useStaticQuery } from 'gatsby'

const WebHostingPage = () => {
    const { hosting } = useStaticQuery(graphql`
  query hosting {
    hosting: file(relativePath: {eq: "web-hosting.jpeg"}) {
      childImageSharp {
        fluid {
          src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
    return (
        <Layout>
            <Squares>
                <Container>
                    <PageInfoSection
                        title={`web`}
                        blueTitle="hosting"
                        text="We’re with you every step of the process- even after designing your platform. Webfluent hosts will register your domain, launch the site, and provide monitoring so you don’t have to."
                    >
                        <ImageCard image={hosting.childImageSharp.fluid} text='Web Hosting' />
                    </PageInfoSection>
                </Container>
            </Squares>
        </Layout>
    )
}

export default WebHostingPage
